import { Page, Navbar } from "./";
import { CircularProgress } from "@mui/material";

export const LoadingState = () => (
  <Page>
    <Navbar title="Loading…" />
    <div className="col-span-12 grid place-items-center h-screen">
      <CircularProgress />
    </div>
  </Page>
);
